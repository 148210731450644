import { parse } from "@config/theme";
export const dotContainer = parse({
  position: "relative"
}, "d160r2qk");
export const dotTrack = parse({
  position: "absolute",
  display: "grid",
  alignItems: "center"
}, "ddgmpq3");
export const dot = parse({
  bg: "shade400",
  display: "block"
}, "djnkyti");
export const container = "c121vqll";

require("./SliderIndicators.styles.linaria.module.css");